<template>
    <apexchart ref="chart" type="line" :options="options" :series="series" :width="width" :height="height"></apexchart>
</template>

<script>
import API_HISOTRY_GRAPH from '../API/reading/historyGraph'
import API_SCOL_HISOTRY_GRAPH from '../API/reading/scolHistoryGraph'
export default {
    components: {
    },
    props: {
        xrayType: {
            default : ""
        },
        xrayNo: {
            default : 0
        },
        width: {
            default : '100%'
        },
        height: {
            default: '100%'
        },
        isScoliosis: {
            default: false
        },
        isReport: {
            default: true
        }
    },
    data() {
        return {
            loading: false,
            data1List: [],
            data2List: [],
            timeList: [],
            options: {
                chart: {
                    animations: {
                        easing: 'easeinout',
                        speed: 500,
                    },
                    foreColor: '#000',
                    id: 'vuechart-example',
                    type: 'line',
                    background: {opacity: 0},
                    zoom: {enabled: false},
                    toolbar: {tools: {download: false}},
                },
                theme: {mode: 'dark'},
                xaxis: {
                    tooltip: {enabled: false},
                    labels: {style: {fontSize: '9.5px'}},
                    categories: this.timeList,
                },
                colors: ['#eb4d4b', '#30336b'],
            },
            series: []
        };
    },
    watch: {},
    created() { },
    async mounted() {
        if(!this.isScoliosis) await this.getHistoryGraph();
        else await this.getScolHistoryGraph();
    },
    destroyed() { },
    methods: {
        async getHistoryGraph() {
            if (this.loading) return;
            this.loading = true;
            let res = await API_HISOTRY_GRAPH.request(this.xrayType, this.xrayNo);
            if (res.isSuccess) {
                const dataList = res.list ?? [];
                this.initDataList(dataList);
                switch (this.xrayType) {
                    case 'spine':
                        this.setSpineGraph(dataList);
                        break;
                    case 'hands':
                        this.setHandsGraph(dataList);
                        break;
                }
                this.loading = false;
            } else {
                this.showPopup(res.errorMsg, res.status);
                this.loading = false;
            }
            this.$emit('loading', this.loading)
        },
        async getScolHistoryGraph() {
            if (this.loading) return;
            this.loading = true;
            let res = await API_SCOL_HISOTRY_GRAPH.request(this.xrayType, this.xrayNo);
            if (res.isSuccess) {
                const dataList = res.list ?? [];
                this.initDataList(dataList);
                this.setScoliosisGraph(dataList);
                this.loading = false;
            } else {
                this.showPopup(res.errorMsg, res.status);
                this.loading = false;
            }
            this.$emit('loading', this.loading)
        },
        initDataList(dataList) {
            if (!Array.isArray(dataList)) {
                console.error('dataList is not an array');
                return;
            }
            this.data1List = [];
            this.data2List = [];
            this.timeList = [];
        },
        setSpineGraph(dataList) {
            dataList.forEach(element => {
                if (element && typeof element.data1 === 'number' && element.pictureDt) {
                    this.data1List.push(element.data1);
                    const date = new Date(element.pictureDt);
                    this.timeList.push(this.setDate(date));
                } else {
                    console.error('Invalid data format in dataList:', element);
                }
            });
            this.series = [
                {
                    name: "최대각도",
                    data: this.data1List,
                },
            ];
            this.setOptions('spine', this.isReport);
        },
        setHandsGraph(dataList) {
            dataList.forEach(element => {
                if (element && typeof element.data1 === 'number' && element.pictureDt) {
                    this.data1List.push(element.data1);
                    const date = new Date(element.pictureDt);
                    let formattedList = []
                    formattedList.push(this.setDate(date))
                    formattedList.push(`(${element.dataStr})`)
                    this.timeList.push(formattedList);
                } else {
                    console.error('Invalid data format in dataList:', element);
                }
            });
            this.series = [
                {
                    name: "뼈 나이",
                    data: this.data1List,
                },
            ];
            this.setOptions('hands', this.isReport);
        },
        setScoliosisGraph(dataList) {
            dataList.forEach(element => {
                if (element && typeof element.data1 === 'number' && element.pictureDt) {
                    this.data1List.push(element.data1);
                    const date = new Date(element.pictureDt);
                    this.timeList.push(this.setDate(date));
                } else {
                    console.error('Invalid data format in dataList:', element);
                }
            });
            this.series = [
                {
                    name: "Incidence of progression",
                    data: this.data1List,
                },
            ];
            this.setOptions('scoliosis', this.isReport);
        },
        setDate(date) {
            let year = date.getFullYear();
            let month = String(date.getMonth() + 1).padStart(2, '0');
            let day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        setOptions(type) {
            let xaxisOption = {
                xaxis: {categories: this.timeList},
            }
            switch (type) {
                case 'spine':
                    this.options = {
                        ...this.options,
                        ...xaxisOption,
                        ...{    
                            yaxis: {
                                labels: {
                                    formatter: function (val) {
                                        return val.toFixed(1) + " °"
                                    },
                                },
                                forceNiceScale: true
                            },
                            legend: {showForSingleSeries: true}
                        }
                    }
                    break;
                case 'hands':
                    this.options = {
                        ...this.options,
                        ...xaxisOption,
                        ...{
                            yaxis: {
                                labels: {
                                    formatter: function (val) {
                                        let result = "";
                                        const years = Math.floor(val);
                                        const months = Math.round((val - years) / 8.333 * 100);
                                        result = years + "세";
                                        if (months > 0) {
                                            result += " " + months + "개월";
                                        }
                                        return result;
                                    },
                                    minWidth: 45,
                                    forceNiceScale: true,
                                },
                            }
                        }
                    }
                    break;
                default:
                    this.options = {
                        ...this.options,
                        ...xaxisOption,
                        ...{
                            yaxis: {
                                labels: {
                                    formatter: function (val) { return val.toFixed(1) + "%" }
                                },
                                forceNiceScale: true,
                                min: 0,
                                max: 100,
                                tickAmount: 6 
                            },
                        }
                    }
                    break;
                }
        }
    },
    computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
</style>
